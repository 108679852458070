var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  useEdgesState,
  useNodesState,
  addEdge,
  reconnectEdge
} from "reactflow";
import { Position } from "reactflow";
const getNodeEditorInfo = (json) => {
  const edge = [];
  const node = Object.entries(json).map(([key, value], index) => {
    const reservedKeys = ["begin", "end", "id"];
    if (reservedKeys.includes(key)) return null;
    const { next, position, name, id, style } = value;
    const [xVal, yVal] = position;
    if (next) {
      (Array.isArray(next) ? next : [next]).forEach((next2) => {
        edge.push({ id: `${key}-${next2}`, source: key, target: next2 });
      });
    }
    return {
      type: name === "Input" ? "input" : name === "Output" ? "output" : "custom",
      id: key,
      position: { x: Math.floor(xVal), y: Math.floor(yVal) },
      data: { label: key, id, name },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      style
    };
  }).filter(Boolean);
  return {
    node,
    edge
  };
};
const convertNodeEditorToJSON = (params) => {
  const { nodeInfo, edgeInfo, initialNode } = params;
  const newJson = __spreadValues({}, initialNode);
  nodeInfo.forEach((node) => {
    const key = node.id;
    const value = {
      id: node.data.id,
      name: node.data.name,
      position: [node.position.x, node.position.y]
    };
    const nextEdge = edgeInfo.filter((edge) => edge.source === key);
    if (nextEdge.length > 0) {
      const nexts = nextEdge.map((edge) => edge.target);
      value.next = nexts.length === 1 ? nexts[0] : nexts;
    }
    newJson[key] = value;
  });
  return newJson;
};
export const useNodeProps = (props) => {
  const { node, edge } = useMemo(() => getNodeEditorInfo(props.value ? props.value : {}), [props.value]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const afterNodes = useRef([]);
  const afterEdges = useRef([]);
  const { onNodeDoubleClick: onNodeDblClick, onChange } = props;
  useEffect(() => {
    if (JSON.stringify(afterNodes.current) !== JSON.stringify(nodes) || afterNodes.current.length !== node.length && node.length > 0) {
      setNodes(node);
    }
    if (JSON.stringify(afterEdges.current) !== JSON.stringify(edges) || afterEdges.current.length !== edge.length && edge.length > 0) {
      setEdges(edge);
    }
  }, [node, edge]);
  useEffect(() => {
    afterEdges.current = edges;
    onChange({
      value: convertNodeEditorToJSON({
        nodeInfo: nodes,
        edgeInfo: edges,
        initialNode: props.value
      })
    });
  }, [edges]);
  const onNodeDragStop = useCallback((event, node2) => {
    const copyNodes = structuredClone(nodes);
    const index = copyNodes.findIndex((element) => element.id === node2.id);
    const newPositionNode = copyNodes[index];
    newPositionNode.position = node2.position;
    copyNodes.splice(index, 1, newPositionNode);
    afterNodes.current = copyNodes;
    onChange({
      value: convertNodeEditorToJSON({
        nodeInfo: copyNodes,
        edgeInfo: edges,
        initialNode: props.value
      })
    });
  }, [nodes]);
  const reconnectDone = useRef(true);
  const onReconnectStart = useCallback(() => {
    reconnectDone.current = false;
  }, []);
  const onReconnect = useCallback((oldEdge, newConnection) => {
    reconnectDone.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
  }, []);
  const onReconnectEnd = useCallback((some, edge2) => {
    if (!reconnectDone.current) {
      setEdges((eds) => eds.filter((ed) => ed.id !== edge2.id));
    }
    reconnectDone.current = true;
  }, []);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  const onNodeDoubleClick = useCallback(onNodeDblClick || (() => {
  }), [
    onNodeDblClick
  ]);
  return {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onReconnectStart,
    onReconnect,
    onReconnectEnd,
    onNodeDoubleClick,
    onNodeDragStop
  };
};
