var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var modules_exports = {};
__export(modules_exports, {
  addObjects: () => addObjects,
  checkPreviewMode: () => checkPreviewMode,
  executeFunction: () => executeFunction,
  getItems: () => getItems,
  getProperties: () => getProperties,
  getValue: () => getValue,
  getValueFromBind: () => getValueFromBind
});
module.exports = __toCommonJS(modules_exports);
var import_slice = require("./slice");
var import_mods = require("../mods");
var import_modules = require("../builder/modules");
var import_context = require("./context");
var import_form_and_table = require("@bsgp/form-and-table");
var import_json5 = __toESM(require("json5"));
var import_lib_core = require("@bsgp/lib-core");
var import_lib_api = require("@bsgp/lib-api");
var import_cache = require("cache");
const lib = {
  "@bsgp/lib-api": require("@bsgp/lib-api"),
  "@bsgp/lib-core": require("@bsgp/lib-core"),
  "@bsgp/lib-date": require("@bsgp/lib-date"),
  "@bsgp/lib-export": require("@bsgp/lib-export"),
  "@bsgp/lib-quantity": require("@bsgp/lib-quantity"),
  "@bsgp/lib-amount": require("@bsgp/lib-amount"),
  jsbarcode: require("jsbarcode")
};
const reducedApiHub = {
  get: import_lib_api.apiHub.get,
  getAsync: import_lib_api.apiHub.getAsync,
  post: import_lib_api.apiHub.post,
  postAsync: import_lib_api.apiHub.postAsync,
  patch: import_lib_api.apiHub.patch,
  patchAsync: import_lib_api.apiHub.patchAsync,
  put: import_lib_api.apiHub.put,
  putAsync: import_lib_api.apiHub.putAsync
};
const getValueFromBind = (data, prop) => {
  if (!data) {
    return prop;
  }
  const params = (0, import_modules.getBindParams)(prop);
  return params.reduce((acc, each) => {
    const cleanedValue = each.replace("{{", "").replace("}}", "").trim();
    const newValue = (0, import_mods.getValueByPath)(data, cleanedValue);
    return acc.replace(each, newValue === void 0 ? "" : newValue);
  }, prop);
};
const getProperties = (comp, { formKey, fieldKey, tableKey, props, functions }) => {
  const { _state } = props;
  const { data } = _state;
  const propsKeys = Object.keys(comp).filter(
    (key) => !["bindData", "key"].includes(key)
  );
  const conv = comp.conv ? comp.conv.value : void 0;
  const newProps = propsKeys.reduce((acc, propKey) => {
    const prop = (0, import_lib_core.clone)(comp[propKey]);
    if (prop.ignore === true) {
      return acc;
    }
    let propValue;
    if (Object.hasOwn(prop, "value")) {
      propValue = getValueFromBind(comp.bindData || data, prop.value);
    } else {
      propValue = getValueFromBind(comp.bindData || data, prop);
    }
    switch (propKey) {
      case "state": {
        if (
          //* template literal일 때
          propValue.startsWith("{") && propValue.endsWith("}")
        ) {
          const literalValue = `{=$${propValue}}`;
          acc[propKey] = literalValue;
        } else if (propValue) {
          acc[propKey] = convertByType(propValue, prop.type, {
            props,
            key: propKey,
            context: {},
            functions
          });
        } else {
          acc[propKey] = window.sap.ui.core.ValueState.None;
        }
        break;
      }
      case "onSelect": {
        const options = Object.entries(comp);
        const checkList = options.reduce(
          (acc2, [optionKey, optionValue]) => {
            if (optionKey === "closeDialogOnSelect" && optionValue.value === "true") {
              acc2.closeDialogOnSelect = true;
            } else if (optionKey === "mode" && ["s", "single"].includes(optionValue.value)) {
              acc2.selectMode = true;
            }
            return acc2;
          },
          {
            closeDialogOnSelect: false,
            selectMode: false
          }
        );
        acc[propKey] = (fn) => fn.getMetaFuncContent({
          // dialogKey,
          // mainKey,
          // componentKey,
          tableKey,
          type: propKey,
          functionUid: propValue,
          functions,
          conv,
          autoClose: !!(checkList.closeDialogOnSelect && checkList.selectMode)
        });
        break;
      }
      case "properties": {
        acc.properties = {};
        const entries = Object.entries(prop);
        entries.forEach((each) => {
          const propsKey = each[0];
          if (each[1].ignore === true) {
            return;
          }
          const propsValue = getValue(
            [
              { key: propsKey, value: getValueFromBind(comp.bindData, each[1]) }
            ],
            {
              props,
              fieldKey,
              functions
            }
          );
          acc.properties[propsKey] = propsValue;
        });
        break;
      }
      case "valueHelpV2": {
        const { dialogId, onRequest, onConfirm } = prop;
        if (dialogId === void 0) {
          break;
        }
        acc[propKey] = {
          dialogId: getValueFromBind(comp.bindData, dialogId.value)
        };
        if (onRequest) {
          acc[propKey].onRequest = (fn) => fn.getMetaFuncContent({
            // dialogKey,
            // mainKey,
            // componentKey,
            tableKey,
            type: "onRequest",
            functionUid: onRequest.value,
            functions,
            conv
          });
        }
        if (onConfirm) {
          acc[propKey].onConfirm = (fn) => fn.getMetaFuncContent({
            // dialogKey,
            // mainKey,
            // componentKey,
            tableKey,
            type: "onConfirm",
            functionUid: onConfirm.value,
            functions,
            conv
          });
        }
        break;
      }
      case "unit": {
        const { unit } = comp;
        if (unit.type === "Path") {
          acc[propKey] = (0, import_mods.getValueByPath)(data, unit.value);
        } else {
          acc[propKey] = unit.value;
        }
        break;
      }
      case "list": {
        const { key, list } = comp;
        acc.list = getValue([{ key, value: list }], {
          props,
          fieldKey,
          functions
        });
        break;
      }
      default: {
        if (propKey.startsWith("on")) {
          acc[propKey] = (fn) => fn.getMetaFuncContent({
            tableKey,
            formKey,
            fieldKey,
            functions,
            componentKey: comp.key || fieldKey,
            type: propKey,
            functionUid: propValue,
            conv
          });
          return acc;
        }
        if ((0, import_lib_core.isArray)(propValue)) {
          return acc;
        }
        acc[propKey] = convertByType(propValue, prop.type, {
          props,
          key: propKey,
          context: {},
          functions
        });
        break;
      }
    }
    return acc;
  }, {});
  if (!import_modules.SectionsList.includes(comp.type) && !["Dimension", "Measure"].includes(comp.type)) {
    if (newProps.onChange === void 0) {
      newProps.onChange = (fn) => fn.getMetaFuncContent({
        // dialogKey,
        formKey,
        tableKey,
        fieldKey,
        componentKey: comp.key || fieldKey,
        functions,
        type: "onChange",
        functionUid: "",
        conv
      });
    }
  }
  return newProps;
};
const addObjects = (builder, {
  forms,
  tables,
  sections,
  codeeditors,
  nodeeditors,
  functions,
  props
}) => {
  const { _state } = props;
  const { data } = _state;
  if ((0, import_lib_core.isTruthy)(forms)) {
    forms.filter((each) => each.ignore !== true).forEach((form, fIdx) => {
      const formData = __spreadValues({
        title: form.title,
        onSelect: (fn) => fn.selectFormId()
      }, getProperties(form, {
        props,
        functions
      }));
      builder.addForm(form.key, formData);
      form.containers.filter((each) => each.ignore !== true).forEach((container, cIdx) => {
        const contData = __spreadValues({}, getProperties(container, {
          props,
          functions
        }));
        builder.addContainer(container.key, contData);
        container.fields.filter((each) => each.ignore !== true).reduce((acc, field) => {
          if (field.binding) {
            const dataList = (0, import_mods.getValueByPath)(data, field.binding);
            if (dataList) {
              dataList.forEach((each) => {
                const newField = (0, import_lib_core.clone)(field);
                newField.bindData = each;
                newField.key = getValueFromBind(
                  newField.bindData,
                  newField.key
                );
                acc.push(newField);
              });
            }
          } else {
            acc.push((0, import_lib_core.clone)(field));
          }
          return acc;
        }, []).forEach((field) => {
          const { components } = field;
          const convValues = components.filter((each) => each.ignore !== true).reduce((acc, each) => {
            if (each.conv) {
              acc = acc.concat(each.conv.value);
            } else {
              acc = acc.concat("");
            }
            return acc;
          }, []);
          builder.addField(field.key, __spreadProps(__spreadValues({}, getProperties(field, {
            props,
            functions
          })), {
            value: getValue(
              components.filter((each) => each.ignore !== true).map((each) => __spreadProps(__spreadValues({}, each), { bindData: field.bindData })),
              {
                formKey: form.key,
                fieldKey: field.key,
                props,
                functions
              }
            ),
            conv: convValues.length === 1 ? convValues[0] : convValues,
            component: getSingleOrMultiComp(
              components.filter((each) => each.ignore !== true).map((each) => __spreadProps(__spreadValues({}, each), { bindData: field.bindData })),
              {
                // dialogKey,
                formKey: form.key,
                fieldKey: field.key,
                props,
                functions
              }
            )
          }));
        });
      });
    });
  }
  if ((0, import_lib_core.isTruthy)(tables)) {
    const selectedTableList = [];
    const hasSpecificTablesForForm = forms && forms.findIndex((form) => (0, import_lib_core.tryit)(() => (0, import_lib_core.isTruthy)(form.table.value), false)) >= 0;
    if (hasSpecificTablesForForm === true) {
      forms.filter((form) => form.key && form.key === data.selectedFormId).forEach((form) => {
        const tableValue = (0, import_lib_core.tryit)(() => form.table.value) || [];
        if (tableValue) {
          selectedTableList.push(...tableValue);
        }
      });
    }
    renderTables(builder, {
      tables: tables.filter((each) => each.ignore !== true),
      data,
      selectedTableList,
      props,
      hasSpecificTablesForForm,
      functions
    });
  }
  if ((0, import_lib_core.isTruthy)(sections)) {
    sections.filter((each) => each.ignore !== true).forEach((section) => {
      builder.addSection(section.key, __spreadProps(__spreadValues({}, getProperties(section, {
        props,
        functions
      })), {
        components: buildComponent(
          section.components,
          props,
          functions
        ),
        sections: buildSection(
          section.sections,
          props,
          functions
        )
      }));
    });
  }
  if ((0, import_lib_core.isTruthy)(codeeditors)) {
    codeeditors.filter((each) => each.ignore !== true).forEach((codeeditor) => {
      builder.addCode(codeeditor.key, __spreadProps(__spreadValues({
        title: codeeditor.title
      }, getProperties(codeeditor, {
        props,
        functions
      })), {
        value: getValue([codeeditor], { props, functions }),
        buttons: codeeditor.components.filter((each) => each.ignore !== true).map((component) => {
          return {
            name: component.key,
            value: component.text ? component.text : getValue([component], {
              props,
              functions
            }),
            component: import_form_and_table.ft[component.type || "Button"](__spreadValues({}, getProperties(component, {
              props,
              functions
            })))
          };
        })
      }));
    });
  }
  if ((0, import_lib_core.isTruthy)(nodeeditors)) {
    nodeeditors.filter((each) => each.ignore !== true).forEach((nodeeditor) => {
      builder.addNode(nodeeditor.key, __spreadProps(__spreadValues({
        title: nodeeditor.title
      }, getProperties(nodeeditor, {
        props,
        functions
      })), {
        value: getValue([nodeeditor], { props, functions }),
        toolbar: {
          properties: {},
          content: nodeeditor.components.filter((each) => each.ignore !== true).map((component) => {
            return {
              name: component.key,
              value: component.text ? component.text : getValue([component], {
                props,
                functions
              }),
              component: import_form_and_table.ft[component.type || "Button"](__spreadValues({}, getProperties(component, {
                props,
                functions
              })))
            };
          })
        }
      }));
    });
  }
};
function convertByType(value, type, { props, key, context, functions }) {
  var _a, _b;
  const { _state } = props;
  const { data, meta } = _state;
  switch (type) {
    case "Function": {
      context.componentKey = key;
      const returnedValue = executeFunction(props, {
        functionIdentifier: [`component(${key})`, `property(value)`].join(":"),
        functions: functions || meta.functions,
        uid: value,
        moreContext: context,
        isAsync: false
      });
      return returnedValue;
    }
    case "Path":
      return (0, import_mods.getValueByPath)(data, value);
    case "Mode":
      return (0, import_lib_core.isArray)(value) ? value.includes((_a = meta.paths[0]) == null ? void 0 : _a.mode) : value === ((_b = meta.paths[0]) == null ? void 0 : _b.mode);
    case "Number":
      return (0, import_lib_core.tryit)(() => Number(value), value);
    case "Object":
    case "Array":
      return (0, import_lib_core.tryit)(() => import_json5.default.parse(value), value);
    case "Boolean": {
      return (0, import_lib_core.tryit)(() => value === "true", false);
    }
    case "String":
      if (value && value.startsWith("i18n.")) {
        return import_form_and_table.refI18n.current.getText(value.replace("i18n.", ""));
      }
      return value;
    default:
      return value;
  }
}
const getValue = (components, { props, formKey, fieldKey, returnContextKeys, functions }) => {
  const { _state } = props;
  const { data } = _state;
  const context = {
    formKey,
    componentKey: void 0
  };
  if (returnContextKeys === true) {
    return Object.keys(context);
  }
  const results = components.map((comp) => {
    const { key, value: valueFromMeta, bindData } = comp;
    if (valueFromMeta === void 0) {
      const compKey = key || fieldKey;
      return (0, import_lib_core.tryit)(() => data.forms[formKey][compKey]);
    }
    const newValue = getValueFromBind(bindData, valueFromMeta.value);
    return convertByType(newValue, valueFromMeta.type, {
      props,
      key,
      context,
      functions
    });
  });
  if (results.length === 0) {
    return "";
  } else if (results.length === 1) {
    return results[0];
  } else {
    return results;
  }
};
const buildComponent = (components, props, functions) => {
  return components.filter((each) => each.ignore !== true).map((component) => {
    return __spreadProps(__spreadValues({}, import_form_and_table.ft[component.type || "Text"](__spreadValues({}, getProperties(component, {
      props,
      functions
    })))), {
      name: component.key,
      value: component.text ? component.text : getValue([component], { props, functions })
    });
  });
};
const buildSection = (sections, props, functions) => {
  if ((0, import_lib_core.isFalsy)(sections)) return [];
  return sections.filter((each) => each.ignore !== true).map((section) => {
    const newSec = import_form_and_table.ft.Section(__spreadProps(__spreadValues({}, getProperties(section, {
      props,
      functions
    })), {
      name: section.key,
      components: buildComponent(
        section.components,
        props,
        functions
      ),
      sections: buildSection(
        section.sections,
        props,
        functions
      )
    }));
    if (["Form", "Table"].includes(section.type)) {
      const newKey = section.type.toLowerCase() + "s";
      Object.keys(import_modules.metaKeyStucture[newKey]).forEach((key) => {
        if (section[key] !== void 0) {
          const newBuilder = (0, import_form_and_table.formTable)();
          const objParams = {
            functions,
            props
          };
          objParams[newKey] = [section];
          addObjects(newBuilder, objParams);
          const newData = newBuilder.done();
          const subData = newData[newKey.replace(/s{1}$/, "")][section.key];
          if (section.type === "Table") {
            newSec[key] = subData.component[key];
            newSec.items = subData.component.items;
          } else {
            newSec[key] = subData[key];
          }
        }
      });
    } else if (["Wizard"].includes(section.type)) {
      newSec.steps = section.steps.map((step) => {
        return __spreadProps(__spreadValues({}, getProperties(step, {
          props,
          functions
        })), {
          name: step.key,
          sections: buildSection(
            step.sections,
            props,
            functions
          )
        });
      });
    } else if (section.type.endsWith("Chart")) {
      newSec.dimensions = (section.dimensions || []).map((dim) => {
        return __spreadProps(__spreadValues({}, getProperties(dim, {
          props,
          functions
        })), {
          name: dim.key
        });
      });
      newSec.measures = (section.measures || []).map((mes) => {
        return __spreadProps(__spreadValues({}, getProperties(mes, {
          props,
          functions
        })), {
          name: mes.key
        });
      });
    }
    return newSec;
  });
};
const getItems = (table, { props, returnContextKeys, functions }) => {
  const { _state } = props;
  const context = {
    tableKey: table.key
  };
  if (returnContextKeys === true) {
    return Object.keys(context);
  }
  const { items } = table;
  if ((0, import_lib_core.isTruthy)(items) && items.ignore !== true) {
    const uid = items.value;
    return executeFunction(props, {
      functions: functions || _state.meta.functions,
      functionIdentifier: `table(${table.key}):property(items)`,
      uid,
      moreContext: context,
      isAsync: false
    });
  } else if ((0, import_lib_core.isTruthy)(_state.data.tables[table.key])) {
    return _state.data.tables[table.key];
  }
};
const renderTables = (builder, {
  tables,
  data,
  selectedTableList,
  props,
  hasSpecificTablesForForm,
  functions
}) => {
  tables.forEach((table, tIdx) => {
    if (selectedTableList.length > 0 && !selectedTableList.includes(table.key)) {
      return;
    } else if (selectedTableList.length === 0 && hasSpecificTablesForForm === true) {
      return;
    }
    const tblProperties = getProperties(
      Object.keys(table).reduce((acc, tKey) => {
        if (["columns", "items"].includes(tKey)) {
          return acc;
        }
        acc[tKey] = table[tKey];
        return acc;
      }, {}),
      {
        props,
        functions
      }
    );
    if (tblProperties.properties.visible === false) {
      return;
    }
    builder.addTable(table.key, __spreadValues({
      title: table.title
    }, tblProperties));
    if (table.isSearch) {
      builder.addToolbarSearch();
    }
    table.columns.filter((each) => each.ignore !== true).reduce((acc, col) => {
      if (col.binding) {
        const dataList = (0, import_mods.getValueByPath)(data, col.binding);
        if (dataList) {
          dataList.forEach((each) => {
            const newCol = (0, import_lib_core.clone)(col);
            newCol.bindData = each;
            newCol.key = getValueFromBind(newCol.bindData, newCol.key);
            acc.push(newCol);
          });
        }
      } else {
        acc.push((0, import_lib_core.clone)(col));
      }
      return acc;
    }, []).forEach((column) => {
      const colProps = getProperties(column, {
        tableKey: table.key,
        props,
        functions
      });
      const colComponents = column.components.filter((each) => each.ignore !== true).map((each) => __spreadProps(__spreadValues({}, each), { bindData: column.bindData }));
      if (colComponents.length === 0) {
        delete colProps.component;
      } else if (colComponents.length === 1) {
        const colComp = colComponents[0];
        colProps.component = import_form_and_table.ft[colComp.type](__spreadValues({}, getProperties(colComp, {
          tableKey: table.key,
          props,
          functions
        })));
      } else if (colComponents.length > 1) {
        colProps.component = colComponents.map((colComp) => {
          return import_form_and_table.ft[colComp.type](colComp.key, __spreadValues({}, getProperties(colComp, {
            tableKey: table.key,
            props,
            functions
          })));
        });
      }
      builder.addColumn(column.key, colProps);
    });
    builder.addItems(getItems(table, { props, functions }));
    const getBtnOptions = (btn) => __spreadValues({
      text: btn.text
    }, getProperties(btn, { props, functions }));
    table.toolbars && table.toolbars.filter((each) => each.ignore !== true).forEach((toolbar) => {
      const { buttons } = toolbar;
      buttons.filter((each) => each.ignore !== true).forEach((button) => {
        if (button.isAction) {
          builder.addToolbarAction(button.key, __spreadValues({
            text: button.text
          }, getProperties(button, {
            props,
            functions
          })));
        } else {
          if (button.buttons && button.buttons.length > 0) {
            builder.addToolbarButton(button.key, [
              getBtnOptions(button),
              ...button.buttons.map((btn) => getBtnOptions(btn))
            ]);
          } else {
            builder.addToolbarButton(
              button.key,
              getBtnOptions(button)
            );
          }
        }
      });
    });
  });
  return builder;
};
const getSingleOrMultiComp = (components, { formKey, fieldKey, props, functions }) => {
  const results = components.reduce((acc, comp) => {
    const { key: originKey, type, bindData } = comp;
    let key = originKey;
    if (bindData) {
      key = getValueFromBind(bindData, key);
      comp.key = key;
    }
    acc = acc.concat(
      import_form_and_table.ft[type](key || fieldKey, __spreadValues({}, getProperties(comp, {
        formKey,
        fieldKey,
        props,
        functions
      })))
    );
    return acc;
  }, []);
  if (results.length === 0) {
    return "";
  } else if (results.length === 1) {
    return results[0];
  } else {
    return results;
  }
};
const getFunction = (uid, { functions }) => {
  var _a;
  if (!uid) {
    throw new Error(`Function ID is not provided`);
  }
  const functionString = ((_a = functions[uid]) == null ? void 0 : _a.content) || "";
  if (!functionString) {
    if (uid === "global") {
      return new Function("return {};");
    } else {
      throw new Error(`Function Body is empty with ID ${uid}`);
    }
  }
  if (uid === "global") {
    return new Function(functionString);
  }
  return new Function(
    "context",
    [
      // ["return", isAsync ? "async" : "", "() => {"].join(" "),
      "return",
      functionString
      // "}"
    ].join(" ")
  );
};
const getFormsTablesData = ({
  props,
  resultData,
  targetObject,
  isRootSection = true
}) => {
  const { _dispatch } = props;
  if ((0, import_lib_core.isTruthy)(targetObject.forms)) {
    targetObject.forms.forEach((form) => {
      const newFormData = resultData[`forms.${form.key}`];
      if (newFormData) {
        form.containers.forEach((container) => {
          container.fields.forEach((field) => {
            field.components.forEach((comp) => {
              if (Object.hasOwn(newFormData, comp.key)) {
                _dispatch(
                  import_slice.rendererActions._updateFormData({
                    formKey: form.key,
                    componentKey: comp.key,
                    value: newFormData[comp.key]
                  })
                );
              }
            });
          });
        });
      }
    });
  }
  if ((0, import_lib_core.isTruthy)(targetObject.tables)) {
    targetObject.tables.forEach((table) => {
      const newTableData = resultData[`tables.${table.key}`];
      if (newTableData) {
        _dispatch(
          import_slice.rendererActions._overwriteTableData({
            key: table.key,
            value: newTableData
          })
        );
      }
    });
  }
  if ((0, import_lib_core.isTruthy)(targetObject.sections)) {
    targetObject.sections.forEach((section) => {
      const newSectionData = resultData[`sections.${section.key}`];
      if (newSectionData && isRootSection === true) {
        _dispatch(
          import_slice.rendererActions._updateComponentData({
            componentCtg: "sections",
            componentKey: section.key,
            value: newSectionData
          })
        );
      }
      if (section.sections && section.sections.length > 0) {
        getFormsTablesData({
          props,
          resultData,
          targetObject: {
            sections: section.sections
          },
          isRootSection: false
        });
      }
      if (["Form", "Table"].includes(section.type)) {
        const newParentKey = section.type.toLowerCase() + "s";
        getFormsTablesData({
          props,
          resultData,
          targetObject: {
            [newParentKey]: [section]
          }
        });
      }
    });
  }
  if ((0, import_lib_core.isTruthy)(targetObject.codeeditors)) {
    targetObject.codeeditors.forEach((codeeditor) => {
      const newCodeEditorData = resultData[`codeeditors.${codeeditor.key}`];
      if (newCodeEditorData) {
        _dispatch(
          import_slice.rendererActions._updateComponentData({
            componentCtg: "codeeditors",
            componentKey: codeeditor.key,
            value: newCodeEditorData
          })
        );
      }
    });
  }
  if ((0, import_lib_core.isTruthy)(targetObject.nodeeditors)) {
    targetObject.nodeeditors.forEach((nodeeditor) => {
      const newNodeEditorData = resultData[`nodeeditors.${nodeeditor.key}`];
      if (newNodeEditorData) {
        _dispatch(
          import_slice.rendererActions._updateComponentData({
            componentCtg: "nodeeditors",
            componentKey: nodeeditor.key,
            value: newNodeEditorData
          })
        );
      }
    });
  }
  const dialogDataKey = `dialogs.${targetObject.key}`;
  const dialogResultData = resultData[dialogDataKey];
  if (dialogResultData !== void 0) {
    if (Object.hasOwn(dialogResultData, "isOpen")) {
      _dispatch(
        import_slice.rendererActions._updateDialogData({
          dialogKey: targetObject.key,
          key: "isOpen",
          value: dialogResultData.isOpen
        })
      );
    }
  }
};
const getParams = (meta, match) => {
  var _a;
  const { entryPath } = match.params;
  const origin = (_a = meta.paths[0]) == null ? void 0 : _a.origin;
  if (entryPath === void 0) {
    return {};
  }
  const arrEntryPath = entryPath.split("/").filter(Boolean);
  const arrPattern = origin.split("/").filter(Boolean);
  const matchParams = arrPattern.reduce((acc, part, idx) => {
    if (part.startsWith(":")) {
      const paramName = part.replace(":", "");
      acc[paramName] = arrEntryPath[idx];
    }
    return acc;
  }, {});
  return matchParams;
};
const previewPrefix = "/lc-ui5/preview";
const checkPreviewMode = (match) => {
  return match.path === `${previewPrefix}/:entryPath+`;
};
function wrapNavFunc(funcName, history, match, metaID, data) {
  return (...args) => {
    import_cache.cacheRef.current.set(metaID, data);
    const [firstArg, ...restArgs] = args;
    const isPreview = checkPreviewMode(match);
    if (isPreview) {
      const path = (0, import_lib_core.isString)(firstArg) ? firstArg : firstArg.pathname;
      if (path.startsWith("/") && !path.startsWith(previewPrefix)) {
        const newPath = [previewPrefix, path].join("");
        const newFirstArg = (0, import_lib_core.isString)(firstArg) ? newPath : __spreadProps(__spreadValues({}, firstArg), { pathname: newPath });
        return history[funcName](newFirstArg, ...restArgs);
      }
    }
    return history[funcName](...args);
  };
}
function wrapHistory(history, location, match, metaID, data) {
  return {
    push: wrapNavFunc("push", history, match, metaID, data),
    replace: wrapNavFunc("replace", history, match, metaID, data),
    state: location.state
  };
}
function wrapCache(curCache, metaId) {
  return {
    get: (key) => {
      try {
        return curCache.get((0, import_lib_core.defined)(key, metaId));
      } catch (ex) {
        return null;
      }
    },
    set: (...args) => {
      const [key, data] = args.length === 1 ? [void 0, args[0]] : args;
      return curCache.set((0, import_lib_core.defined)(key, metaId), data);
    }
  };
}
const executeFunction = (props, {
  functions,
  uid,
  moreContext,
  isAsync,
  functionIdentifier,
  returnContextKeys,
  oEvent
}) => {
  var _a;
  const { history, _state, currentUser, match, location } = props;
  const { data, meta } = _state;
  const params = getParams(meta, match);
  const mode = (_a = meta.paths[0]) == null ? void 0 : _a.mode;
  const context = __spreadValues({
    state: data,
    history: wrapHistory(history, location, match, meta.id, data),
    api: reducedApiHub,
    currentUser,
    mode,
    params,
    s3: import_lib_api.s3,
    file: import_lib_api.file,
    fn: {},
    oEvent,
    i18n: import_form_and_table.refI18n.current,
    getState: () => import_context.dataRef.current || data,
    setState: setState(props),
    cache: wrapCache(import_cache.cacheRef.current, meta.id)
  }, moreContext);
  context.require = (name) => {
    if (lib[name] === void 0) {
      throw new Error(
        [
          name,
          "\uBAA8\uB4C8\uC774 \uC81C\uACF5\uB418\uC9C0 \uC54A\uB294\uAC83 \uAC19\uC2B5\uB2C8\uB2E4. \uC2DC\uC2A4\uD15C \uAD00\uB9AC\uC790\uC5D0\uAC8C \uBB38\uC758\uD574\uC8FC\uC138\uC694"
        ].join(" ")
      );
    } else {
      return lib[name];
    }
  };
  const contextKeys = Object.keys(context);
  if (returnContextKeys === true) {
    return contextKeys;
  }
  (0, import_lib_core.tryit)(() => {
    const getGlobalFunctions = getFunction("global", {
      functions
    });
    context.fn = getGlobalFunctions();
    if ((0, import_lib_core.isFunction)(context.fn)) {
      context.fn = context.fn(context);
    }
  });
  let newData;
  let customFunc;
  try {
    customFunc = getFunction(uid, {
      functions
    });
  } catch (ex) {
    console.log(ex.message);
    return;
  }
  try {
    newData = customFunc()(context);
  } catch (err) {
    console.log(err.message);
    return;
  }
  if (!isAsync) {
    return newData;
  }
  return newData.then((resultData) => {
    return setState(props)(resultData);
  });
};
const setState = (props) => (resultData) => {
  const { _dispatch, _state } = props;
  const { meta } = _state;
  if (resultData) {
    if ((0, import_lib_core.isFunction)(resultData)) {
      _dispatch(
        import_slice.rendererActions._overwriteState((draft) => {
          resultData(draft);
        })
      );
      return;
    }
    if (resultData.messages) {
      _dispatch(
        import_slice.rendererActions._overwriteState((draft) => {
          draft.messages = resultData.messages;
        })
      );
    }
    if (Object.keys(meta).includes("dialogs")) {
      meta.dialogs.forEach((dialog) => {
        getFormsTablesData({
          props,
          resultData,
          targetObject: dialog
        });
      });
    }
    getFormsTablesData({
      props,
      resultData,
      targetObject: meta
    });
    if ((0, import_lib_core.isTruthy)(resultData.hints)) {
      const { hints } = resultData;
      Object.entries(hints).forEach(([key, newHintData]) => {
        _dispatch(
          import_slice.rendererActions._updateDataHints({
            hintKey: key,
            data: newHintData
          })
        );
      });
    }
    if (resultData.toCloseDialog === false) {
      return resultData.toCloseDialog;
    }
  }
};
