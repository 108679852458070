var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { SplitterLayout } from "@ui5/webcomponents-react";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
const useStyles = createUseStyles({
  splitter: {
    "&": {
      backgroundColor: "var(--sapGroup_ContentBackground)",
      borderRadius: "0.5rem"
    },
    "& [role=separator]": {
      height: "auto"
    }
  }
});
const FtSplitterLayout = (props) => {
  const newProps = __spreadValues({}, props);
  delete newProps.resizable;
  delete newProps.properties;
  delete newProps.minSize;
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement(
    SplitterLayout,
    __spreadProps(__spreadValues(__spreadValues({}, props.properties), newProps), {
      className: clsx(classes.splitter),
      options: {
        resetOnSizeChange: true
      }
    }),
    props.children
  );
};
export default FtSplitterLayout;
