var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { tryit } from "@bsgp/lib-core";
import { FlexBox } from "@/components";
import { isArray } from "@bsgp/lib-core";
import ComponentBuilder from "@/builders/common/ComponentBuilder";
import { getComponentName } from "@/lib/utils";
function FormComponentIterator({
  hasMultiComponents,
  element,
  elName
}) {
  if (hasMultiComponents) {
    const fieldComponents = element.field.component;
    return fieldComponents.map((fieldComponent, index) => {
      const fieldName = getComponentName({ elName, fieldComponent });
      const isFullWidth = (component) => tryit(() => component.properties.width) === "100%";
      const renderingComponentBuilder = /* @__PURE__ */ React.createElement(
        ComponentBuilder,
        __spreadProps(__spreadValues({
          name: fieldName,
          id: fieldName,
          key: fieldName,
          value: element.field.value[index],
          conv: isArray(element.field.conv) ? element.field.conv[index] : element.field.conv
        }, fieldComponent), {
          properties: __spreadValues({
            width: "auto"
          }, fieldComponent.properties)
        })
      );
      return isFullWidth(fieldComponent) ? /* @__PURE__ */ React.createElement(FlexBox, { name: `${fieldName}_layout`, key: `${fieldName}_layout` }, renderingComponentBuilder) : renderingComponentBuilder;
    });
  } else {
    const { value, conv } = element.field;
    const component = element.field.component;
    return /* @__PURE__ */ React.createElement(
      ComponentBuilder,
      __spreadProps(__spreadValues({
        id: element.name,
        key: element.name,
        value,
        conv
      }, component), {
        properties: __spreadValues({
          style: {
            display: "inline-flex",
            padding: 0
          }
        }, component.properties)
      })
    );
  }
}
export default FormComponentIterator;
