var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { VBox, HBox, Grid, SplitterLayout } from "@/components";
import { SplitterElement } from "@/components";
import { MetaSorter } from "@/lib/metaSorter";
import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import SectionItemBuilder, {
  SectionItem
} from "./SectionItemBuilder";
import FormIterator from "../form/FormIterator";
import TableIterator from "../table/TableIterator";
import WizardBuilder from "../wizard/Builder";
import PanelBuilder from "../panel/Builder";
import CardBuilder from "../card/Builder";
import BarChartBuilder from "../charts/BarChart";
import DonutChartBuilder from "../charts/DonutChart";
import LineChartBuilder from "../charts/LineChart";
import RadarChartBuilder from "../charts/RadarChart";
import {
  constructFormData,
  constructTableData
} from "@/lib/constructors";
import { propsRef } from "@/ref";
const useStyles = createUseStyles({
  container: {
    padding: ({ padding }) => padding === void 0 ? "1rem" : padding,
    gap: "1rem",
    width: "100%"
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold"
  }
});
function getChild(type, restProps, orderedComponents, props) {
  const newRestProps = __spreadValues({}, restProps);
  delete newRestProps.type;
  delete newRestProps.sections;
  delete newRestProps.refineEventHookName;
  switch (type) {
    case "VBox": {
      return /* @__PURE__ */ React.createElement(VBox, __spreadValues({}, newRestProps), /* @__PURE__ */ React.createElement(
        SectionItemBuilder,
        {
          components: orderedComponents,
          sectionType: type
        }
      ));
    }
    case "HBox": {
      return /* @__PURE__ */ React.createElement(HBox, __spreadValues({}, newRestProps), /* @__PURE__ */ React.createElement(
        SectionItemBuilder,
        {
          components: orderedComponents,
          sectionType: type
        }
      ));
    }
    case "Panel": {
      return /* @__PURE__ */ React.createElement(PanelBuilder, __spreadProps(__spreadValues({}, newRestProps), { title: props.title }), /* @__PURE__ */ React.createElement(
        SectionItemBuilder,
        {
          components: orderedComponents,
          sectionType: type
        }
      ));
    }
    case "Card": {
      return /* @__PURE__ */ React.createElement(CardBuilder, __spreadProps(__spreadValues({}, props), { type }));
    }
    case "BarChart": {
      return /* @__PURE__ */ React.createElement(BarChartBuilder, __spreadProps(__spreadValues({}, props), { type }));
    }
    case "DonutChart": {
      return /* @__PURE__ */ React.createElement(DonutChartBuilder, __spreadProps(__spreadValues({}, props), { type }));
    }
    case "LineChart": {
      return /* @__PURE__ */ React.createElement(LineChartBuilder, __spreadProps(__spreadValues({}, props), { type }));
    }
    case "RadarChart": {
      return /* @__PURE__ */ React.createElement(RadarChartBuilder, __spreadProps(__spreadValues({}, props), { type }));
    }
    case "Wizard": {
      return /* @__PURE__ */ React.createElement(WizardBuilder, __spreadValues({}, newRestProps));
    }
    case "Grid": {
      return /* @__PURE__ */ React.createElement(Grid, __spreadValues({}, newRestProps), orderedComponents.map(({ data }) => {
        return data.map((comp, index) => {
          return /* @__PURE__ */ React.createElement(
            "div",
            {
              "data-layout-span": comp.properties["data-layout-span"],
              "data-layout-indent": comp.properties["data-layout-indent"],
              key: `grid-${index}`,
              style: { width: "100%" }
            },
            /* @__PURE__ */ React.createElement(
              SectionItem,
              __spreadProps(__spreadValues({}, comp), {
                key: `grid-${comp.type}-${index}`,
                refineEventHookName: "useRefineEventForSection"
              })
            )
          );
        });
      }));
    }
    case "Split": {
      return /* @__PURE__ */ React.createElement(SplitterLayout, __spreadValues({}, newRestProps), orderedComponents.map(({ data }, idx) => {
        return data.map((component, index) => {
          const sizeArr = restProps.size || [];
          const size = sizeArr[idx] || "auto";
          const minSizeArr = restProps.minSize || [];
          const minSize = minSizeArr[idx] || 0;
          return /* @__PURE__ */ React.createElement(
            SplitterElement,
            {
              key: `split-${idx}-${index}`,
              resizable: restProps.resizable,
              size,
              minSize
            },
            /* @__PURE__ */ React.createElement(
              SectionItem,
              __spreadProps(__spreadValues({}, component), {
                refineEventHookName: "useRefineEventForSection"
              })
            )
          );
        });
      }));
    }
    case "Form": {
      const newData = constructFormData(
        { [props.name]: props },
        propsRef.current.fn
      );
      return /* @__PURE__ */ React.createElement(
        FormIterator,
        {
          key: "constructor-form-in-section",
          renderData: newData
        }
      );
    }
    case "Table": {
      const newData = constructTableData(
        { [props.name]: { component: props } },
        propsRef.current.fn
      );
      return /* @__PURE__ */ React.createElement(
        TableIterator,
        {
          key: "constructor-table-in-section",
          renderData: newData
        }
      );
    }
    default: {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, "\uC9C0\uC815\uD558\uC9C0 \uC54A\uC740 \uD0C0\uC785\uC758 \uC139\uC158");
    }
  }
}
const SectionBuilder = (props) => {
  const classes = useStyles(props);
  const _a = props, {
    title,
    components,
    name,
    sectionType,
    fullContainer
  } = _a, restProps = __objRest(_a, [
    "title",
    "components",
    "name",
    "sectionType",
    "fullContainer"
  ]);
  const type = props.type === "Section" ? sectionType : props.type;
  const sorter = new MetaSorter(props);
  const orderedComponents = useMemo(
    () => sorter.getOrderedLayout(),
    [props]
  );
  const style = {};
  if (fullContainer === false) {
    style.width = "auto";
  }
  return /* @__PURE__ */ React.createElement(VBox, { "data-cy": name, className: classes.container, style }, title && type !== "Panel" && /* @__PURE__ */ React.createElement("div", { className: classes.title }, title), getChild(type, restProps, orderedComponents, props));
};
export default SectionBuilder;
