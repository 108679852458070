var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var index_ui5_exports = {};
__export(index_ui5_exports, {
  createComponent: () => createComponent
});
module.exports = __toCommonJS(index_ui5_exports);
var import_ui5_lib_rc = require("ui5-lib-rc");
var import_lib_core = require("@bsgp/lib-core");
var import_lib_core2 = require("@bsgp/lib-core");
var import_path = __toESM(require("path"));
var import_common = require("./common");
var import_lib_core3 = require("@bsgp/lib-core");
var import_spark_md5 = __toESM(require("spark-md5"));
let lastHash;
let lastLang;
let lastLocaleState;
const getCircularReplacer = () => {
  const seen = /* @__PURE__ */ new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
function createComponent(state, props, fn, { pageKey, onResize, getProps }) {
  const compFactory = (0, import_ui5_lib_rc.factory)(pageKey);
  const {
    form,
    formContainer,
    formElement,
    vBox,
    hBox,
    component,
    column,
    text,
    button,
    columnListItem,
    link,
    dialog,
    customData
  } = compFactory;
  const getFieldWithFactory = (0, import_common.getField)(compFactory, fn);
  const { title, data, getUI5, history, currentUser } = getProps();
  const { center = false, hCenter = false, vCenter = false } = data;
  const stringData = JSON.stringify(
    { data, currentUser },
    getCircularReplacer()
  );
  const thisHash = import_spark_md5.default.hash(stringData);
  console.log("%cft data:", "color:red", data);
  const containerPage = (0, import_ui5_lib_rc.byId)("containerPage");
  const wasFromFt = customData.value("isFromFt", containerPage);
  if (wasFromFt === "1") {
    if (thisHash === lastHash && !data.nodeeditor && !getUI5) {
      if (lastLang === currentUser.lang && lastLocaleState === currentUser.localeState) {
        console.log("data is same");
        return {
          ignore: true,
          isFromFt: "1"
        };
      }
    }
  }
  lastHash = thisHash;
  lastLang = currentUser.lang;
  lastLocaleState = currentUser.localeState;
  if ((0, import_lib_core2.isFunction)(getUI5)) {
    getUI5(compFactory, fn, import_common.fieldComponent);
  }
  const wrapForms = (0, import_lib_core.tryit)(() => data.wrapForms);
  const wrapSingleTable = (0, import_lib_core.tryit)(() => data.wrapSingleTable);
  const formData = (0, import_common.constructFormData)(data.form, fn);
  const hasFooter = (0, import_lib_core2.isTruthy)(data.footer);
  const forms = (0, import_common.buildForms)(
    form,
    formContainer,
    formElement,
    hBox,
    component,
    customData,
    onResize
  )(formData, { pageKey, getFieldWithFactory, wrapForms, hasFooter });
  const tableData = (0, import_common.constructTableData)(data.table, fn, pageKey);
  const tables = (0, import_common.buildTables)(
    column,
    text,
    columnListItem,
    component,
    hBox,
    vBox,
    customData
  )(tableData, {
    pageKey: ["root", pageKey].join("_"),
    getFieldWithFactory,
    wrapSingleTable
  });
  let iFrameNode;
  if (data.nodeeditor) {
    const nodeeditorID = "nodeeditor_" + (/* @__PURE__ */ new Date()).getTime() + (0, import_lib_core3.makeid)(5);
    const neToolbar = (0, import_common.constructToolbar)(data.nodeeditor.toolbar, "nodeeditor");
    iFrameNode = vBox.set({
      name: "editorWrapper",
      items: [
        (0, import_lib_core2.isTruthy)(neToolbar) && component.set(window.sap.m.OverflowToolbar, {
          name: `nodeeditor_toolbar`,
          settings: __spreadProps(__spreadValues({
            design: window.sap.m.ToolbarDesign.Transparent
          }, neToolbar.properties), {
            content: neToolbar.content.map((bar) => {
              return getFieldWithFactory(bar.name, bar);
            })
          }),
          callback: (comp, isInitial) => {
            if (isInitial) {
              comp.addStyleClass("sapUiSizeCompact");
            }
          }
        }),
        component.set(window.sap.ui.core.HTML, {
          name: "nodeeditor",
          content: `
      <div id="${nodeeditorID}" 
      class="nodeeditor" style="width:100vw; height:60vh;">
      </div>
    `,
          callback: (comp, isInitial) => {
            let maxRetry = 0;
            const onLoadEditor = setInterval(() => {
              const elEditor = window.document.querySelector(
                `#${nodeeditorID}`
              );
              if (elEditor && elEditor.parentElement) {
                console.log("callback:1");
                data.nodeeditor.onLoadEditor(fn)(
                  elEditor,
                  data.nodeeditor.enabled
                );
                clearInterval(onLoadEditor);
              }
              maxRetry += 1;
              if (maxRetry === 10) {
                clearInterval(onLoadEditor);
              }
            }, 200);
          }
        })
      ].filter(Boolean)
    });
  }
  if (data.dialog) {
    if (!data.dialog.content) {
      data.dialog.content = {};
    }
    const cePageKey = `${pageKey}_dialog`;
    const content = Object.keys(data.dialog.content).reduce((acc, key) => {
      switch (key) {
        case "form": {
          const formDataDialog = (0, import_common.constructFormData)(
            data.dialog.content.form,
            fn
          );
          const formsDialog = (0, import_common.buildForms)(
            form,
            formContainer,
            formElement,
            hBox,
            component,
            customData,
            onResize
          )(formDataDialog, {
            pageKey: cePageKey,
            getFieldWithFactory
          });
          return acc.concat(formsDialog);
        }
        case "table": {
          if (data.dialog.content.table) {
            Object.keys(data.dialog.content.table).forEach((tableKey) => {
              data.dialog.content.table[tableKey].component.hideDownloadButton = true;
              data.dialog.content.table[tableKey].component.compactSize = true;
            });
          }
          const tableData2 = (0, import_common.constructTableData)(
            data.dialog.content.table,
            fn,
            pageKey
          );
          const tables2 = (0, import_common.buildTables)(
            column,
            text,
            columnListItem,
            component,
            hBox,
            vBox,
            customData
          )(tableData2, {
            pageKey: cePageKey,
            getFieldWithFactory
          });
          return acc.concat(tables2);
        }
        case "codeeditor": {
          const ceInstances = (0, import_common.buildCodeEditor)(
            { codeeditor: data.dialog.content.codeeditor },
            fn,
            {
              customData,
              component,
              button,
              pageKey: cePageKey,
              getFieldWithFactory
            }
          );
          acc.push(...ceInstances);
          break;
        }
        default: {
          break;
        }
      }
      return acc;
    }, []);
    const buttons = Object.keys((0, import_lib_core.defined)(data.dialog.buttons, {})).map((key) => ({
      name: `${cePageKey}_${key}_dialog`,
      field: {
        value: data.dialog.buttons[key].value,
        component: {
          type: "Button",
          properties: data.dialog.buttons[key].properties
        }
      }
    }));
    const beginButton = buttons[0] && getFieldWithFactory(buttons[0].name, buttons[0].field);
    const endButton = buttons[1] && getFieldWithFactory(buttons[1].name, buttons[1].field);
    component.set(window.sap.m.Dialog, __spreadValues(__spreadValues(__spreadValues({
      name: cePageKey,
      content: vBox.set({
        name: [cePageKey, "ceWrapper"].join("_"),
        height: "100%",
        items: content
      }),
      stretch: true,
      callback: (comp, isInitial) => {
        if (isInitial) {
          comp.addStyleClass("dialog-h-100");
        }
        if (data.dialog.isOpen) {
          if (data.dialog.content.codeeditor) {
            component.get([cePageKey, "codeeditor"].join("_")).setValue(data.dialog.content.codeeditor.value);
          }
          comp.open();
        } else {
          comp.isOpen() && comp.close();
        }
      }
    }, beginButton && { beginButton }), endButton && { endButton }), data.dialog.properties));
  }
  if (data.dialogV2) {
    Object.keys(data.dialogV2).forEach((dialogKey) => {
      const dialogData = data.dialogV2[dialogKey];
      const {
        properties: dlProperties,
        ftData: dlFtData,
        isOpen: isOpenV2,
        fullSize: fullSizeV2,
        title: dlTitle,
        disableEsc,
        beginButtonText,
        hideBeginButton,
        endButtonText,
        onClose,
        onConfirm,
        onCancel
      } = dialogData;
      const formDataDialog = (0, import_common.constructFormData)(dlFtData.form, fn);
      const dialogId = `${dialogKey}_dlgV2`;
      const formsDialog = (0, import_common.buildForms)(
        form,
        formContainer,
        formElement,
        hBox,
        component,
        customData,
        onResize
      )(formDataDialog, {
        pageKey: `${pageKey}_${dialogId}`,
        getFieldWithFactory
      });
      if (dlFtData.table) {
        Object.keys(dlFtData.table).forEach((tableKey) => {
          dlFtData.table[tableKey].component.hideDownloadButton = true;
          dlFtData.table[tableKey].component.compactSize = true;
        });
      }
      const tableData2 = (0, import_common.constructTableData)(dlFtData.table, fn, pageKey);
      const tables2 = (0, import_common.buildTables)(
        column,
        text,
        columnListItem,
        component,
        hBox,
        vBox,
        customData
      )(tableData2, {
        pageKey: ["dialog", pageKey, dialogKey].join("_"),
        getFieldWithFactory
      });
      const ceArray = [];
      if (dlFtData.codeeditor) {
        const ceInstances = (0, import_common.buildCodeEditor)(dlFtData.codeeditor, fn, {
          customData,
          component,
          button,
          pageKey: dialogId,
          getFieldWithFactory
        });
        ceArray.push(...ceInstances);
      }
      const beginButtonOnPress = () => (oEvent) => {
        const toCloseDialog = oEvent.toCloseDialog;
        const oHeader = oEvent.getSource().getParent();
        const oDialog = oHeader.getParent();
        const oCustomData = oDialog.getCustomData().find((data2) => {
          return data2.getKey() === "origin";
        });
        if (oCustomData) {
          oCustomData.setValue("confirm");
        } else {
          oDialog.addCustomData(
            customData.set({
              name: [dialogId, "origin"].join("_"),
              key: "origin",
              value: "confirm"
            })
          );
        }
        if (toCloseDialog !== false) {
          oDialog.close();
        }
      };
      const beginButtonName = [dialogId, "begin"].join("-");
      const beginButtonData = {
        name: beginButtonName,
        field: {
          value: beginButtonText || "\uD655\uC778",
          component: {
            type: "Button",
            properties: __spreadValues({
              type: window.sap.m.ButtonType.Emphasized
            }, button.get(beginButtonName) ? {} : {
              press: beginButtonOnPress
            })
          }
        }
      };
      const beginButton = hideBeginButton ? false : getFieldWithFactory(beginButtonData.name, beginButtonData.field);
      const endButtonData = {
        name: [dialogId, "end"].join("-"),
        field: {
          value: endButtonText || "\uB2EB\uAE30",
          component: {
            type: "Button",
            properties: {
              type: window.sap.m.ButtonType.Reject,
              press: () => (oEvent) => {
                const toCloseDialog = oEvent.toCloseDialog;
                const oHeader = oEvent.getSource().getParent();
                const oDialog = oHeader.getParent();
                const oCustomData = oDialog.getCustomData().find((data2) => {
                  return data2.getKey() === "origin";
                });
                if (oCustomData) {
                  oCustomData.setValue("close");
                } else {
                  oDialog.addCustomData(
                    customData.set({
                      name: [dialogId, "origin"].join("_"),
                      key: "origin",
                      value: "close"
                    })
                  );
                }
                if (toCloseDialog !== false) {
                  oDialog.close();
                }
              }
            }
          }
        }
      };
      const endButton = getFieldWithFactory(
        endButtonData.name,
        endButtonData.field
      );
      const titleText = dlTitle || dlProperties.title;
      const dialogTitle = titleText && getFieldWithFactory([dialogId, "title"].join("-"), {
        value: titleText
      });
      const dialogButtons = [beginButton, endButton, dialogTitle].filter(
        Boolean
      );
      dialog.set(__spreadProps(__spreadValues(__spreadValues({
        name: dialogId,
        content: vBox.set({
          name: [dialogId, "ceWrapper"].join("_"),
          height: "100%",
          items: formsDialog.concat(tables2).concat(ceArray)
        }),
        escapeHandler: disableEsc === true ? (promise) => {
          promise.reject("");
        } : void 0,
        stretch: (0, import_lib_core.defined)(fullSizeV2, true),
        customHeader: component.set(window.sap.m.OverflowToolbar, {
          name: [dialogId, "header"].join("-"),
          content: dialogButtons,
          callback: (comp) => {
            comp.addStyleClass("sapUiSizeCompact");
          }
        })
      }, onClose && { afterClose: onClose(fn) }), dlProperties), {
        callback: (comp, isInitial) => {
          if (isInitial) {
            comp.addStyleClass("dialog-h-100");
          }
          if (isOpenV2 === true) {
            comp.isOpen() || comp.open();
          } else if (isOpenV2 === false) {
            comp.isOpen() && comp.close();
          }
        }
      }));
      if (onConfirm || onCancel) {
        dialogButtons.forEach(
          (each) => (0, import_common.refineEventForDialog)(each, {
            onConfirm: onConfirm && onConfirm(fn),
            onBeforeCancel: onCancel && onCancel(fn)
          })
        );
      }
    });
  }
  let oCode;
  if (data.codeeditor) {
    const [oToolbar, oEditor] = (0, import_common.buildCodeEditor)(
      data.codeeditor.hasOwnProperty("value") || data.codeeditor.hasOwnProperty("properties") ? { codeeditor: data.codeeditor } : data.codeeditor,
      fn,
      {
        customData,
        component,
        button,
        pageKey,
        getFieldWithFactory
      }
    );
    if (oToolbar || oEditor) {
      oCode = vBox.set({
        name: "ceWrapper",
        height: "100%",
        items: [oToolbar, oEditor].filter(Boolean)
      });
    }
  }
  const footerData = (0, import_common.constructFooterData)(data.footer);
  const btnToggleForm = forms.length === 0 || tables.length === 0 ? [] : [
    {
      name: "toggle_forms_separator",
      field: {
        value: "",
        component: {
          type: "Separator"
        }
      }
    },
    {
      name: "toggle_forms",
      field: {
        value: "",
        component: {
          type: "Button",
          properties: {
            icon: "sap-icon://collapse-group",
            press: () => (oEvent) => {
              const oThis = oEvent.getSource();
              if (oThis.getIcon() === "sap-icon://collapse-group") {
                oThis.setIcon("sap-icon://expand-group");
                oThis.addStyleClass("ft-notify-form-is-hidden");
              } else {
                oThis.setIcon("sap-icon://collapse-group");
                oThis.removeStyleClass("ft-notify-form-is-hidden");
              }
              (0, import_lib_core.defined)(forms, []).forEach((oComp, idx) => {
                if (wrapForms === true && idx > 0) {
                  return;
                }
                if (oComp) {
                  oComp.toggleStyleClass("ft-hide-with-effect");
                  if (oComp.getMetadata().getName() === "sap.m.IconTabBar") {
                  } else {
                  }
                }
              });
            }
          }
        }
      }
    }
  ];
  const footerComponents = footerData.concat(btnToggleForm).map((each) => {
    return getFieldWithFactory(each.name, each.field);
  });
  const roofComponents = [];
  const historyLinks = (0, import_lib_core2.isArray)(data.historyLinks) ? data.historyLinks : [];
  if ((0, import_lib_core2.isTruthy)(historyLinks)) {
    const lastIndex = historyLinks.length - 1;
    const presentLink = history.location.pathname;
    roofComponents.push(
      component.set(window.sap.m.Breadcrumbs, {
        name: "history_links",
        currentLocationText: title,
        links: historyLinks.filter((each, idx) => {
          let href = each.url || each.href || "";
          href = import_path.default.resolve(presentLink, href);
          return !(lastIndex === idx && presentLink.toLowerCase() === href.toLowerCase());
        }).map((hLink, idx) => {
          let href = hLink.url || hLink.href || "";
          if (href) {
            href = import_path.default.resolve(presentLink, href);
            href += history.location.search;
          }
          return link.set({
            name: ["hLink-", idx].join(""),
            text: hLink.text,
            // href
            press: () => {
              history.push(href);
            }
          });
        }),
        callback: (comp) => {
          comp.addStyleClass("sapUiSmallMarginBegin sapUiTinyMarginTop");
        }
      })
    );
  }
  if (hasFooter) {
    roofComponents.push(
      component.set(window.sap.m.OverflowToolbar, {
        name: "toolHeader",
        altPrefix: "",
        settings: {
          design: window.sap.m.ToolbarDesign.Solid,
          content: footerComponents
        },
        callback: (comp, isInitial) => {
          if (isInitial) {
            comp.addStyleClass("sapUiSizeCompact");
          }
          if (data.stickyFooter) {
            comp.addStyleClass("ft-sticky");
          } else {
            comp.removeStyleClass("ft-sticky");
          }
        }
      })
    );
  }
  const wrapper = vBox.set(__spreadProps(__spreadValues(__spreadValues({
    name: "wrapper"
  }, hCenter !== false ? {
    alignItems: window.sap.m.FlexAlignItems.Center
  } : {
    alignItems: window.sap.m.FlexAlignItems.Stretch
  }), center === true || vCenter === true ? {
    justifyContent: window.sap.m.FlexJustifyContent.Center
  } : {
    justifyContent: window.sap.m.FlexJustifyContent.Start
  }), {
    fitContainer: true,
    backgroundDesign: window.sap.m.BackgroundDesign.Solid,
    items: [
      vBox.set({
        name: "innerWrapper",
        fitContainer: false,
        height: "100%",
        backgroundDesign: window.sap.m.BackgroundDesign.Solid,
        items: [].concat(forms).concat(tables).concat(oCode).filter(Boolean),
        callback: (comp, isInitial) => {
          if (isInitial) {
            comp.addStyleClass("max-height-100p");
          }
        }
      }),
      iFrameNode
    ].filter(Boolean),
    callback: (comp, isInitial) => {
      if (oCode) {
        comp.removeStyleClass("ft-height-100p");
        comp.removeStyleClass("ft-height-fit-content");
        comp.addStyleClass("ft-height-fill-available");
      } else {
        if (vCenter === true) {
          comp.removeStyleClass("ft-height-fit-content");
          comp.removeStyleClass("ft-height-fill-available");
          comp.addStyleClass("ft-height-100p");
        } else {
          comp.removeStyleClass("ft-height-100p");
          comp.removeStyleClass("ft-height-fill-available");
          comp.addStyleClass("ft-height-fit-content");
        }
      }
    }
  }));
  const page = {
    isFromFt: "1",
    title,
    content: roofComponents.concat([wrapper]),
    floatingFooter: false,
    customFooterContent: []
  };
  return page;
}
