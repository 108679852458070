var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection
} from "@ui5/webcomponents-react";
function VBox(props) {
  const _a = props, { style, children, gap, properties = {} } = _a, restProps = __objRest(_a, ["style", "children", "gap", "properties"]);
  const newStyle = {};
  if (properties.height) {
    newStyle.height = properties.height;
  }
  if (properties.width) {
    newStyle.width = properties.width;
  }
  const newProperties = __spreadValues({}, properties);
  delete newProperties.height;
  delete newProperties.width;
  return /* @__PURE__ */ React.createElement(
    FlexBox,
    __spreadValues(__spreadProps(__spreadValues({}, newProperties), {
      direction: FlexBoxDirection.Column,
      alignItems: props.alignItems || FlexBoxAlignItems.Stretch,
      style: __spreadValues(__spreadValues({ gap }, style), newStyle)
    }), restProps),
    children
  );
}
VBox.displayName = "VBox";
export default VBox;
